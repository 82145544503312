<template>
  <VRow column>
    <VCol style="flex: 0 0 64px">
      <IndexHeader
        mode="standalone"
        :owner="this"
        :can-create="$isTeamOwner"
      >
        <template #create-action>
          <ExpandableButton
            v-if="$isTeamOwner"
            large
            icon
            text="+ Invite Admins"
            button-class="wethrive sendInviationBtn mr-1"
            button-style="box-shadow: none !important;"
            @click="onInviteAdmin"
          />
        </template>
      </IndexHeader>
    </VCol>
    <VCol style="flex: 1 1 auto">
      <VTabs
        lazy
        grow
        slider-color="primary"
        class="d-none"
      >
        <VTab
          v-for="(tab, key) in tabs"
          :to="{
            hash: tab.url
          }"
          :key="key"
        >
          {{ tab.title }}
        </VTab>
      </VTabs>
      <VTabsItems
        touchless
        :value="activeUrlTab"
      >
        <VTabItem>
          <AdminLicences @inviteAdmin="onInviteAdmin" />
        </VTabItem>
        <VTabItem>
          <Export :prepared-config="config" />
        </VTabItem>
      </VTabsItems>
      <Dialog
        enable-footer-slots
        max-width="542px"
        custom-class="invite-dialog"
        :dismissable="false"
        :is-dialog-open="dialogs.invitation.status"
        :action-style="{
          'border-top': '1px solid #FAF9FF',
          'box-shadow': '0px 0px 25px 0px #0000000D',
        }"
      >
        <template #footer-left>
          <VBtn
            text
            height="54px"
            @click="dialogs.invitation.status = false"
          >
            <span 
              class="body-1"
              style="color: #000000 !important;"
            >
              Cancel
            </span>
          </VBtn>
        </template>
        <template #footer-right>
          <VBtn
            color="primary"
            height="54px"
            min-width="116px"
            class="black--text"
            style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
            :disabled="dialogs.invitation.disabled"
            :loading="dialogs.invitation.loading"
            @click="$refs.inviteAdmin.sendInvite()"
          >
            <span 
              class="text-body-2"
              style="font-weight: 600;"
            >
              {{ !$team.is_free && $team.subscription_plan && !$team.subscription_plan.is_enterprise ? "Confirm Purchase" : "Confirm" }}
            </span>
          </VBtn>
        </template>
        <InviteAdmin 
          ref="inviteAdmin"
          @close="dialogs.invitation.status = false" 
          @disabled="(status) => dialogs.invitation.disabled = status"
          @loading="(status) => dialogs.invitation.loading = status"
        />
      </Dialog>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import { mapActions } from "vuex";
import Export from "./Export";
import InviteAdmin from "./InviteAdmin";
import AdminLicences from "./AdminLicences";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";

export default {
  name: "UserIndex",
  components: {
    Export,
    IndexHeader,
    AdminLicences,
    InviteAdmin,
  },
  mixins: [SearchMixin],
  data () {
    return {
      dialogs: {
        invitation: {
          disabled: true,
          loading: false,
          status: false,
        },
      },
      modelType: "Admins & Users",
    };
  },
  computed: {
    tabs() {
      return [
        {
          title: "Admin Licenses",
          url: "",
          enabled: true,
        },
        {
          title: "Export",
          url: "#export",
          enabled: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "users/getIndexConfig",
    }),
    async onGetIndexConfig () {
      return await this.doGetIndexConfig();
    },
    onInviteAdmin () {
      this.dialogs.invitation.disabled = true;
      this.dialogs.invitation.status = true;
    },
  },
};
</script>
<style lang="scss">
.sendInviationBtn {
  border-radius: 10px !important;
  padding: 10px 30px !important;

  .v-btn__content {
    font-size: 18px !important;
    font-weight: 600;
    color: #000000 !important;
  }
}
.invite-dialog {
  .v-card__actions {
    .col {
      padding: 0px 44px;
    }
  }
}
</style>