<template>
  <VRow style="padding: 24px 30px 12px;">
    <VCol cols="12">
      <h1 
        class="font-weight-black text-center"
        style="font-size: 32px;"
      >
        You Sure?
      </h1>
    </VCol>
    <VCol cols="12">
      <VCard
        color="#F4F4F9"
        class="px-5 py-2"
        style="border-radius: 10px;"
      >
        <VList color="transparent">
          <VListItem class="px-0">
            <VListItemAvatar 
              class="mr-2"
              style="border: 1px solid #A9A9A9;"
            >
              <img :src="admin.photo_url">
            </VListItemAvatar>
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 18px;"
              >
                {{ admin.name }}
              </VListItemTitle>
              <VListItemSubtitle 
                class="text-body-1"
                style="color: #666666;"
              >
                {{ admin.email }}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VCard>
    </VCol>
    <VCol 
      cols="12"
      style="font-size: 15px;"
    >
      <p class="mb-2 mt-n2">
        By removing this Admin, they will no longer be able to:
      </p>
      <ul>
        <li>view and manage all new Hubs in the Workspace</li>
        <li>edit Workspace settings</li>
        <li>add Admin users to Workspace</li>
      </ul>
      <!-- <p class="mb-2 mt-4">
        Please tell us why you’re making this change:
      </p>
      <VRadioGroup
        v-model="selectedReason"
        column
        hide-details 
        class="mt-2"
      > 
        <VRadio
          v-for="(reason, index) in reasons"
          color="#FF2A7A"
          class="custom-radio removal-reasons py-1"
          :key="index"
          :label="reason.title"
          :value="reason.title"
        >
          <template #label>
            <span 
              class="black--text" 
              style="font-size: 15px;"
            >
              {{ reason.title }}
            </span>
          </template>
        </VRadio>
      </VRadioGroup> -->
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "RemoveAdmin",
  props: {
    admin: {
      type: Object,
      default: () => {},
    },
    admins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedReason: null,
      reasons: [
        { title: "They have left the team/company" },
        { title: "They no longer manage or participate in programs" },
        { title: "They weren’t using WeThrive" },
        { title: "The per-license cost of WeThrive is too high" },
      ],
    };
  },
  watch: {
    selectedReason(value) {
      this.$emit("disabled", !value);
    },
  },
  methods: {
    ...mapActions({
      doUpdateSubscription: "payment/updateSubscription",
      doDowngradeUsers:"team/downgradeUsers",
    }),
    save() {
      this.$emit("loading", true);
      const params = {
        admins: this.admins.filter((admin) => admin.id != this.admin.id).map((admin) => admin.id),
      };
      this.doDowngradeUsers(params).then((result) => {
        if(result) {
          this.$team = result;
          this.$bus.$emit("notificationMessage", "Admin removed successfully!");
          this.$emit('removed');
          this.$emit("loading", false);
        }
      });
    },
  },
}
</script>
<style lang="scss">
.removal-reasons {
  .v-input--selection-controls__input .v-icon.mdi-radiobox-blank {
    background-color: white !important;
    color: white !important;
  }
}
</style>