<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isLoading || activity.isCountLoading"
  >
    <VRow 
      class="admin-licences px-5"
      :style="{
        width: $vuetify.breakpoint.lgAndUp ? '75%' : 'auto',
      }"
    >
      <VCol cols="12">
        <h2
          class="text-h6"
          style="font-weight: 600;"
        >
          Admin Licenses
        </h2>
        <h4
          :class="{
            'subtitle-2 pt-2': true,
            'pb-4': !$team.is_free,
          }"
          style="font-weight: 600; color: #666666;"
        >
          Workspace Administrators have access to your team content, can create new program {{ featureName("Programs") }}, and can view, edit, and manage all {{ featureName("programs") }} in your workspace.
        </h4>
        <VCard 
          v-if="!$team.is_free"
          class="pa-2"
          style="border: 1px solid #FFC100;"
        >
          <VCardText>
            <h2
              class="text-body-1"
              style="font-weight: 600;"
            >
              Your Team Plan
            </h2>
            <p
              v-if="notOnEssentialPlan"
              class="mb-1 text-caption black--text"
            >
              You are on the <b>{{ planName }}</b> plan with <b>{{ NumberToWords.toWords(planQuantity) }} ({{ planQuantity }}) admin licenses</b>. You may invite unlimited new Admins below. Team Users are free, but have read-only access to {{ featureName("Programs") }} and cannot modify team settings.
            </p>
            <p 
              v-else 
              class="mb-1 text-caption black--text"
            >
              You are on the <b>{{ planName }}</b> plan with <b>{{ NumberToWords.toWords(planQuantity) }} ({{ planQuantity }}) admin licenses</b>. Your total license fee is <b>${{ totalMonthlyFee }}/month</b>. You may invite new Admins below at an additional <b>${{ perMonthPrice }}/month each</b>. Team Users are free, but have read-only access to {{ featureName("Programs") }} and cannot modify team settings.
            </p>
            <div
              v-if="!this.$team.is_free"
              class="text-caption"
              style="color: #0A5894; font-weight: 600;"
            >
              <span 
                class="text-decoration-underline"
                style="cursor: pointer;"
                @click="$router.push({
                  name: 'plan',
                  params: {
                    team: $team.slug,
                  },
                })"
              >
                Plan & Billing Info
              </span> >
            </div>
          </VCardText>
        </VCard>
      </VCol>
      <VCol cols="12">
        <h2
          class="black--text" 
          style="font-size: 15px; font-weight: 600;"
        >
          Workspace Admins
        </h2>
        <p 
          v-if="notOnEssentialPlan || $team.is_free"
          class="text-caption"
          style="font-weight: 600; color: #666666;"
        >
          To change your number of admins, select which users to downgrade to User.
        </p>
        <p 
          v-else
          class="text-caption"
          style="font-weight: 600; color: #666666;"
        >
          Each Admin license on the {{ planName }} is ${{ perMonthPrice }}/month, billed {{ billingCycle }}. To change your number of admins, select which users to downgrade to User.
        </p>
        <VList>
          <VListItem 
            v-if="!$isTeamOwner" 
            class="px-0"
          >
            <VListItemAvatar 
              class="mr-2"
              style="border: 1px solid #A9A9A9;"
            >
              <img :src="$team.owner.photo_url">
            </VListItemAvatar>
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 18px;"
              >
                {{ $team.owner.name }} (Owner)
              </VListItemTitle>
              <VListItemSubtitle 
                class="text-body-1"
                style="color: #666666;"
              >
                {{ $team.owner.email }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction
              class="font-italic"
              style="color: #767676; font-size: 15px;"
            >
              Team creator cannot be removed
            </VListItemAction>
          </VListItem>
          <VListItem class="px-0">
            <VListItemAvatar 
              class="mr-2"
              style="border: 1px solid #A9A9A9;"
            >
              <img :src="$user.photo_url">
            </VListItemAvatar>
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 18px;"
              >
                {{ $user.name }} (You)
              </VListItemTitle>
              <VListItemSubtitle 
                class="text-body-1"
                style="color: #666666;"
              >
                {{ $user.email }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction
              class="font-italic"
              style="color: #767676; font-size: 15px;"
            >
              <template v-if="$isTeamOwner">
                Team creator cannot be removed
              </template>
              <template v-else>
                You cannot remove yourself
              </template>
            </VListItemAction>
          </VListItem>
          <VRow v-if="activity.isLoadingUsers">
            <VCol class="text-center">
              <VProgressCircular 
                indeterminate
                color="primary"
              />
            </VCol>
          </VRow>
          <template v-else>
            <VListItem
              v-for="(user, index) in data.data"
              class="px-0"
              :key="index"
            >
              <VListItemAvatar 
                class="mr-2"
                style="border: 1px solid #A9A9A9;"
              >
                <img :src="user.photo_url">
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle 
                  class="font-weight-medium"
                  style="font-size: 18px;"
                >
                  {{ user.name }}
                </VListItemTitle>
                <VListItemSubtitle
                  class="text-body-1"
                  style="color: #666666;"
                >
                  {{ user.email }}
                </VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <VBtn
                  height="48px"
                  color="#FF2A7A"
                  class="rounded-pill white--text px-5"
                  @click="onRemoveAdmin(user)"
                >
                  <span style="font-size: 18px; font-weight: 600;">Remove</span>
                </VBtn>
              </VListItemAction>
            </VListItem>
            <infinite-loading
              v-if="canGetMore"
              :identifier="new Date()"
              @infinite="getAdminUsers"
            />
          </template>
        </VList>
      </VCol>
      <VCol cols="12">
        <h2
          style="font-size: 15px; font-weight: 600;"
          :class="{
            'black--text': true,
            'mb-2': $team.is_free,
          }" 
        >
          Pending Invitations
        </h2>
        <p
          v-if="!$team.is_free"
          class="text-caption"
          style="font-weight: 600; color: #666666;"
        >
          Once an invitation is accepted, your total license fee will be updated (if applicable).
        </p>
        <VRow v-if="activity.isLoadingInvitations">
          <VCol class="text-center">
            <VProgressCircular 
              indeterminate
              color="primary"
            />
          </VCol>
        </VRow>
        <VList v-else-if="filteredInvitations.length">
          <VListItem
            v-for="(invitation, index) in filteredInvitations"
            class="px-0"
            :key="index"
          >
            <VListItemAvatar 
              class="mr-2"
              style="border: 1px solid #A9A9A9;"
            >
              <img 
                v-if="invitation.user"
                :src="invitation.user.photo_url"
              >
              <img 
                v-else 
                src="https://www.gravatar.com/avatar/768d6651b348534823b044dc5fe1d068.jpg?s=200&d=mm"
              >
            </VListItemAvatar>
            <VListItemContent>
              <VListItemTitle 
                class="font-weight-medium"
                style="font-size: 18px;"
              >
                {{ invitation.name }}
              </VListItemTitle>
              <VListItemSubtitle
                class="text-body-1"
                style="color: #666666;"
              >
                {{ invitation.email }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction v-if="$isTeamOwner">
              <VBtn
                outlined
                color="#FF2A7A"
                height="38px"
                class="rounded-pill"
                :disabled="invitation.isLoading"
                :loading="invitation.isLoading"
                @click="onCancelInvite(invitation, index)"
              >
                <VIcon
                  size="16px"
                  style="font-weight: 600;"
                >
                  close
                </VIcon>
                <span 
                  class="text-body-2"
                  style="font-weight: 600; color: #E3170A;"
                >
                  Cancel Invite
                </span>
              </VBtn>
            </VListItemAction>
          </VListItem>
        </VList>
        <VAlert
          v-else
          color="#FAF9FF"
          class="text-caption mb-0 black--text"
          style="font-weight: 600;"
        >
          This area will show anyone who’s been invited via email and has not yet accepted. At the moment, your Workspace has no pending admin invitations.
        </VAlert>
      </VCol>
      <VCol 
        v-if="$isTeamOwner"
        cols="12"
      >
        <VList>
          <VListItem class="px-0">
            <VListItemContent>
              <VListItemTitle 
                class="black--text" 
                style="font-size: 15px; font-weight: 600; padding-bottom: 2px;"
              >
                Invite New Admins
              </VListItemTitle>
              <VListItemSubtitle
                v-if="!$team.is_free"
                class="text-caption text-wrap"
                style="font-weight: 600; color: #666666; line-height: 1.25rem;"
              >
                <template v-if="notOnEssentialPlan">
                  Your {{ !$team.trialEnded && !$team.subscription_plan ? planName : "plan" }} allows unlimited Admin Licenses.
                </template>
                <template v-else>
                  Each Admin license on the {{ planName }} is ${{ perMonthPrice }}/month, billed {{ billingCycle }}.
                </template>
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <VBtn
                height="44px"
                color="primary"
                style="border-radius: 10px; padding: 20px 30px; box-shadow: none !important;"
                @click="$emit('inviteAdmin')"
              >
                <span 
                  class="text-body-1"
                  style="font-weight: 600; color: #333333;"
                >
                  + Invite Admins
                </span>
              </VBtn>
            </VListItemAction>
          </VListItem>
        </VList>
      </VCol>
        <Confirm
          alt-view
          persistent
          alt-confirm-text="Yes"
          alt-cancel-text="No"
          alt-title="Cancel Invite?"
          ref="confirmDelete"
        />
        <Dialog
          enable-footer-slots
          max-width="520px"
          custom-class="removeAdminDialog"
          :dismissable="false"
          :is-dialog-open="dialogs.removeAdmin.status"
          :action-style="{
            'border-top': '1px solid #FAF9FF',
            'box-shadow': '0px 0px 25px 0px #0000000D',
          }"
        >
          <template #footer-left>
            <VBtn
              text
              height="54px"
              @click="dialogs.removeAdmin.status = false"
            >
              <span 
                class="body-1"
                style="color: #000000 !important;"
              >
                Cancel
              </span>
            </VBtn>
          </template>
          <template #footer-right>
            <VBtn
              color="#FF2A7A"
              height="54px"
              style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
              :disabled="dialogs.removeAdmin.disabled"
              :loading="dialogs.removeAdmin.loading"
              @click="$refs.removeAdmin.save()"
            >
              <span 
                class="text-body-2 white--text"
                style="font-weight: 600;"
              >
                Remove this Admin
              </span>
            </VBtn>
          </template>
          <RemoveAdmin 
            ref="removeAdmin"
            :admins="data.data"
            :admin="dialogs.removeAdmin.model"
            @removed="onRemoved" 
            @disabled="(status) => dialogs.removeAdmin.disabled = status"
            @loading="(status) => dialogs.removeAdmin.loading = status"
          />
        </Dialog>
    </VRow>
  </VSkeletonLoader>
</template>
<script>
import { mapActions } from "vuex";
import NumberToWords from "number-to-words";
import DeletesItemsMixin from "@/mixins/DeletesItems";
import SubscriptionMixin from "@/mixins/Subscription";
import RemoveAdmin from "./RemoveAdmin";
export default {
  name: "AdminLicences",
  mixins: [DeletesItemsMixin, SubscriptionMixin],
  components: {
    RemoveAdmin,
  },
  data() {
    return {
      NumberToWords,
      invitations: [],
      activity: {
        isLoading: false,
        isLoadingUsers: false,
        isLoadingInvitations: false,
      },
      data: {
        data: [],
        current_page: 0,
        last_page: 0,
        total: 0,
      },
      dialogs: {
        removeAdmin: {
          model: {},
          status: false,
          disabled: false,
          loading: false,
        },
      },
    };
  },
  computed: {
    canGetMore() {
      return this.data.current_page < this.data.last_page;
    },
    removedAdmins() {
      return this.data.data.filter((item) => item.isRemoved === true).map((item) => item.id);
    },
    filteredInvitations() {
      return this.invitations.filter((invitation) => invitation.role == "organization-admin");
    },
    billingCycle() {
      if(this.subscribedPlan) {
        return this.subscribedPlan.billing_cycle == "year" ? "annually" : "monthly";
      }
      return this.subscribedPlan;
    },
  },
  created() {
    this.getAdminUsers();
    this.getInvitations();
    this.$bus.$on("addInvitation", (invitation) => this.invitations.push(invitation));
  },
  methods: {
    ...mapActions({
      doGetAdminUsers: "users/getAll",
      doDeleteInvite: "team/deleteInvite",
      doGetInvitations: "team/invitations",
      doDeleteInvite: "team/deleteInvite",
    }),
    getInvitations() {
      this.activity.isLoadingInvitations = true;
      this.doGetInvitations().then((invitations) => {
        this.invitations = invitations;
        this.activity.isLoadingInvitations = false;
      });
    },
    getAdminUsers($infinite) {
      const params = {
        page: 
            this.data.data && this.data.current_page < this.data.last_page
              ? this.data.current_page + 1
              : 1,
        filter: {
          'team_user.role': ['organization-admin'],
        },
      };
      this.doGetAdminUsers(params).then((result) => {
        this.data.current_page = result.current_page;
        this.data.last_page = result.last_page;
        this.data.total = result.total + 1; // Include team owner
        this.data.data.push(...result.data.map((item) => {
          item.isRemoved = false;
          return item;
        }).filter((item) => item.id != this.$user.id));
        if($infinite) {
          $infinite.loaded();
          if(result.current_page >= result.last_page) {
            $infinite.complete();
          }
        }
        this.activity.isLoading = false;
      });
    },
    onCancelInvite(invitation, key) {
      this.$set(this.invitations[key], "isLoading", true);
      const confirmMessage = "Are you sure you want to cancel this invitation?";
      this.onDeleteItem([invitation], this.doDeleteInvite, confirmMessage, false).then((result) => {
        if(result) {
          this.$delete(this.invitations, key);
          this.$bus.$emit("notificationMessage", "Invitation cancelled successfully!");
        } else {
          this.$set(this.invitations[key], "isLoading", false);
        }
      });
    },
    onRemoveAdmin(user) {
      this.dialogs.removeAdmin.model = user;
      this.dialogs.removeAdmin.status = true;
    },
    onRemoved() {
      this.$set(this.data, "data", this.data.data.filter((item) => item.id != this.dialogs.removeAdmin.model.id));
      if(this.notOnEssentialPlan) {
        this.totalAdmins--;
      } else {
        this.$set(this.$team.subscription_plan, "quantity", this.$team.subscription_plan.quantity - 1);
      }
      this.dialogs.removeAdmin.status = false;
    },
  },
};
</script>
<style lang="scss">
  .removeAdminDialog {
    .v-card__actions {
      .col {
        padding: 0px 24px;
      }
    }
  }
</style>